import { Positions } from './anatomyPositions';

export var anaconfig = {
  default: {
    outlineColor: '',
  },
  /*==============================================================================*/
  /*==============================================================================*/
  /*================ !!! CUSTOMIZATION OF THE FRONT SIDE !!!  ====================*/
  /*==============================================================================*/
  /*==============================================================================*/
  ana1: {
    //head
    isFrontFacing: true,
    hover: 'HEAD', //info of the popup
    url: '', //link to any webpage
    target: 'zoom', // use "new_window", "same_window", "modal" with bootstrap only, or "none"
    enabled: true, //true/false to activate/deactivate
    position: Positions.head,
  },
  ana2: {
    //eye-rt
    isFrontFacing: true,
    hover: 'EYE [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana3: {
    //eye-lt
    isFrontFacing: true,
    hover: 'EYE [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana4: {
    //ear-rt
    isFrontFacing: true,
    hover: 'EAR [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana5: {
    //ear-lt
    isFrontFacing: true,
    hover: 'EAR [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana6: {
    //nose
    isFrontFacing: true,
    hover: 'NOSE',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana7: {
    //mouth
    isFrontFacing: true,
    hover: 'MOUTH',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana8: {
    //neck
    isFrontFacing: true,
    hover: 'NECK',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.neck,
  },
  ana9: {
    //chest
    isFrontFacing: true,
    hover: 'CHEST',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.chest,
  },
  ana10: {
    //abdomen
    isFrontFacing: true,
    hover: 'ABDOMEN',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.abdomen,
  },
  ana11: {
    //pelvis
    isFrontFacing: true,
    hover: 'PELVIS',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.pelvis,
  },
  ana12: {
    //pubis
    isFrontFacing: true,
    hover: 'PUBIS',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.pelvis,
  },
  ana13: {
    //shoulder-rt
    isFrontFacing: true,
    hover: 'SHOULDER [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.shoulderRt,
  },
  ana14: {
    //shoulder-lt
    isFrontFacing: true,
    hover: 'SHOULDER [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.shoulderLt,
  },
  ana15: {
    //arm-rt
    isFrontFacing: true,
    hover: 'ARM [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.armRt,
  },
  ana16: {
    //arm-lt
    isFrontFacing: true,
    hover: 'ARM [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.armLt,
  },
  ana17: {
    //elbow-rt
    isFrontFacing: true,
    hover: 'ELBOW [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.elbowRt,
  },
  ana18: {
    //elbow-lt
    isFrontFacing: true,
    hover: 'ELBOW [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.elbowLt,
  },
  ana19: {
    //forearm-rt
    isFrontFacing: true,
    hover: 'FOREARM [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.forearmRt,
  },
  ana20: {
    //forearm-lt
    isFrontFacing: true,
    hover: 'FOREARM [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.forearmLt,
  },
  ana21: {
    //wrist-rt
    isFrontFacing: true,
    hover: 'WRIST [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.wristRt,
  },
  ana22: {
    //wrist-lt
    isFrontFacing: true,
    hover: 'WRIST [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.wristLt,
  },
  ana23: {
    //hand-rt
    isFrontFacing: true,
    hover: 'HAND [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.handRt,
  },
  ana24: {
    //hand-lt
    isFrontFacing: true,
    hover: 'HAND [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.handLt,
  },
  ana25: {
    //thigh-rt
    isFrontFacing: true,
    hover: 'THIGH [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.thighRt,
  },
  ana26: {
    //thigh-lt
    isFrontFacing: true,
    hover: 'THIGH [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.thighLt,
  },
  ana27: {
    //knee-rt
    isFrontFacing: true,
    hover: 'KNEE [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.kneeRt,
  },
  ana28: {
    //knee-lt
    isFrontFacing: true,
    hover: 'KNEE [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.kneeLt,
  },
  ana29: {
    //leg-rt
    isFrontFacing: true,
    hover: 'LEG [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.legRt,
  },
  ana30: {
    //leg-lt
    isFrontFacing: true,
    hover: 'LEG [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.legLt,
  },
  ana31: {
    //ankle-rt
    isFrontFacing: true,
    hover: 'ANKLE [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.ankleRt,
  },
  ana32: {
    //ankle-lt
    isFrontFacing: true,
    hover: 'ANKLE [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.ankleLt,
  },
  ana33: {
    //foot-rt
    isFrontFacing: true,
    hover: 'FOOT [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footRt,
  },
  ana34: {
    //foot-lt
    isFrontFacing: true,
    hover: 'FOOT [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footLt,
  },
  /*===============================================================================*/
  /*===============================================================================*/
  /*================  !!! CUSTOMIZATION OF THE BACK SIDE !!!  =====================*/
  /*===============================================================================*/
  /*===============================================================================*/
  ana35: {
    //head-back
    hover: 'HEAD [BACK]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.head,
  },
  ana36: {
    //neck-back
    hover: 'NECK [BACK]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.neck,
  },
  ana37: {
    //back
    hover: 'BACK',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.chest,
  },
  ana38: {
    //loin
    hover: 'LOIN',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.abdomen,
  },
  ana39: {
    //buttocks
    hover: 'BUTTOCKS',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.buttocks,
  },
  ana40: {
    //shoulder-back-rt
    hover: 'SHOULDER [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backShoulderRt,
  },
  ana41: {
    //shoulder-back-lt
    hover: 'SHOULDER [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backShoulderLt,
  },
  ana42: {
    //arm-back-rt
    hover: 'ARM [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backArmRt,
  },
  ana43: {
    //arm-back-lt
    hover: 'ARM [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backArmLt,
  },
  ana44: {
    //elbow-back-rt
    hover: 'ELBOW [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backElbowRt,
  },
  ana45: {
    //elbow-back-lt
    hover: 'ELBOW [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backElbowLt,
  },
  ana46: {
    //forearm-back-rt
    hover: 'FOREARM [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backForearmRt,
  },
  ana47: {
    //forearm-back-lt
    hover: 'FOREARM [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backForearmLt,
  },
  ana48: {
    //wrist-back-rt
    hover: 'WRIST [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backWristRt,
  },
  ana49: {
    //wrist-back-lt
    hover: 'WRIST [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backWristLt,
  },
  ana50: {
    //hand-back-rt
    hover: 'HAND [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backHandRt,
  },
  ana51: {
    //hand-back-lt
    hover: 'HAND [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.backHandLt,
  },
  ana52: {
    //hamstring-rt
    hover: 'HAMSTRING [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.bThighRt,
  },
  ana53: {
    //hamstring-lt
    hover: 'HAMSTRING [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.bThighLt,
  },
  ana54: {
    //knee-back-rt
    hover: 'KNEE [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.kneeLt,
  },
  ana55: {
    //knee-back-lt
    hover: 'KNEE [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.kneeRt,
  },
  ana56: {
    //calf-rt
    hover: 'CALF [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.legLt,
  },
  ana57: {
    //calf-lt
    hover: 'CALF [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.legRt,
  },
  ana58: {
    //ankle-back-rt
    hover: 'ANKLE [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footLt,
  },
  ana59: {
    //ankle-back-lt
    hover: 'ANKLE [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footRt,
  },
  ana60: {
    //sole-rt
    hover: 'SOLE [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footLt,
  },
  ana61: {
    //sole-lt
    hover: 'SOLE [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footRt,
  },
  ana62: {
    //foot-back-rt
    hover: 'FOOT [BACK] [RT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footLt,
  },
  ana63: {
    //foot-back-lt
    hover: 'FOOT [BACK] [LT]',
    url: '',
    target: 'zoom',
    enabled: true,
    position: Positions.footRt,
  },
};
