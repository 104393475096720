export enum Muscles {
  TEMPORALIS = 'temporalis',
  SEMISPINALISCAPITIS = 'semispinalisCapitis',
  SUBOCCIPITAL = 'suboccipital',
  FRONTALIS = 'frontalis',
  INFRASPINATUS = 'infraspinatus',
  INTERCOSTAL = 'intercostal',
  LATISSIMUSDORSI = 'latissimusDorsi',
  LEVATOR = 'levator',
  RHOMBOID = 'rhomboid',
  SCALENE = 'scalene',
  STERNOCLEIDOMASTERTOID = 'sternocleidomastertoid',
  SUPRASPINATUS = 'supraspinatus',
  SERRATUSANTERIOR = 'serratusAnterior',
  TERESMAJOR = 'teresMajor',
  TRAPEZIUS = 'trapezius',
  RECTUSABDOMINIS = 'rectusAbdominis',
  GLUTEUSMAXIMUS = 'gluteusMaximus',
  QUADRATUSLUMBORUM = 'quadratusLumborum',
  SUBSCAPULARIS = 'Subscapularis',
  ILIOPSOAS = 'Iliopsoas',
  GLUTEUSMEDIUS = 'gluteusMedius',
  CLAVICULARPECMAJOR = 'clavicularPecMajor',
}
