interface SpotColors {
  upColor: string;
  outline: string;
  overColor: string;
}

export const SpotColors: SpotColors = {
  upColor: '#FF6E6E',
  outline: '#FF6E6E',
  overColor: '#CC2900',
};
