interface Positions {
  head: string;
  neck: string;
  chest: string;
  abdomen: string;
  pelvis: string;
  shoulderRt: string;
  backShoulderRt: string;
  shoulderLt: string;
  backShoulderLt: string;
  armRt: string;
  backArmRt: string;
  armLt: string;
  backArmLt: string;
  elbowRt: string;
  backElbowRt: string;
  elbowLt: string;
  backElbowLt: string;
  forearmRt: string;
  backForearmRt: string;
  forearmLt: string;
  backForearmLt: string;
  wristRt: string;
  backWristRt: string;
  wristLt: string;
  backWristLt: string;
  handRt: string;
  backHandRt: string;
  handLt: string;
  backHandLt: string;
  thighRt: string;
  thighLt: string;
  kneeRt: string;
  kneeLt: string;
  legRt: string;
  legLt: string;
  ankleRt: string;
  ankleLt: string;
  footRt: string;
  footLt: string;
  buttocks: string;
  bThighRt: string;
  bThighLt: string;
}

export const Positions: Positions = {
  //front
  head: '0% 40%',
  neck: '0% 35%',
  chest: '0% 25%',
  abdomen: '0% 10%',
  pelvis: '0% 0%',
  shoulderRt: '10% 25%',
  shoulderLt: '-10% 20%',
  armRt: '15% 20%',
  armLt: '-15% 20%',
  elbowRt: '17.5% 17.5%',
  elbowLt: '-17.5% 17.5%',
  forearmRt: '20% 12.5%',
  forearmLt: '-20% 12.5%',
  wristRt: '25% 5%',
  wristLt: '-15% 5%',
  handRt: '27.5% 0%',
  handLt: '-27.5% 0%',
  thighRt: '5% -5%',
  thighLt: '-5% -5%',
  kneeRt: '5% -17.5%',
  kneeLt: '-5% -17.5%',
  legRt: '5% -30%',
  legLt: '-5% -30%',
  ankleRt: '5% -30%',
  ankleLt: '-5% -30%',
  footRt: '5% -30%',
  footLt: '-5% -30%',
  //back
  backShoulderRt: '-10% 20%',
  backShoulderLt: '15% 30%',
  backArmRt: '-15% 20%',
  backArmLt: '17.5% 20%',
  backElbowRt: '-17.5% 17.5%',
  backElbowLt: '20% 17.5%',
  backForearmRt: '-20% 7.5%',
  backForearmLt: '22.5% 12.5%',
  backWristRt: '-20% 5%',
  backWristLt: '25% 5%',
  backHandRt: '-25% 0%',
  backHandLt: '27.5% 0%',
  buttocks: '0% 0%',
  bThighRt: '-5% -10%',
  bThighLt: '5% -10%',
};
