export enum BodyParts {
  BACKHEAD = 'backHead',
  FRONTHEAD = 'frontHead',
  BACKNECK = 'backNeck',
  FRONTNECK = 'frontNeck',
  BACKSHOULDER = 'backShoulder',
  FRONTSHOULDER = 'frontShoulder',
  UPPERBACK = 'upperBack',
  LOWERBACK = 'lowerBack',
  GLUTES = 'glutes',
  CHEST = 'chest',
}
